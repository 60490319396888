<template>
  <v-form ref="form">
    <card-view
        v-if="dataState.isModalOpen"
        :title="(dataState.modalData===null ? '' : typeof dataState.modalData.id=='undefined' ? 'Создать план работы' : 'Изменить план работы #'+dataState.modalData.id)"
        big
        width="440px" cardClass="position-fixed overflow-auto border-left" style="right:0;bottom:0;top:64px"
        rounded="false"
        elevation="0"
        contentFullSize
    >
      <template v-slot:content v-if="dataState.modalData!==null">
        <log v-if="typeof dataState.modalData.id!=='undefined'" entity-name="UserSpecPlan" :entity-id="dataState.modalData.id" logClass="mx-4 mb-4"/>
        <v-autocomplete
            v-model="userSelectedId"
            :items="users"
            :search-input.sync="usersText"
            label="Специалист или администратор"
            outlined
            dense
            hide-details="auto"
            item-text="name"
            item-value="id"
            :rules="formFieldRules.required"
            class="mx-4 mb-4"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Не найдено</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:item="{item}">
            <v-list-item-avatar class="user">
                <img :src="item.imageUrl" alt="" v-if="item.imageUrl">
            </v-list-item-avatar>
            <v-list-item-content style="width:360px">
              <v-list-item-title>{{item.name}}</v-list-item-title>
              <v-list-item-subtitle>
                <div v-for="userSpec in item.userSpecs" :key="item.id+'_'+userSpec.id" class="text-wrap">
                  {{userSpec.spec.name}}
                  <v-icon small>mdi-chevron-left</v-icon>
                  {{userSpec.comDiv.comBranch.name}}
                  <v-icon v-if="!$tools.isEmpty(userSpec.commentAdmin)" class="ml-2 mr-1" small>mdi-comment-text-outline</v-icon>
                  {{userSpec.commentAdmin}}
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
          <template v-slot:selection="{item}">
            <v-avatar class="mr-2 user" size="24">
              <img :src="item.imageUrl" v-if="item.imageUrl" alt="">
            </v-avatar>
            <div class="mr-2">{{item.name}}</div>
          </template>
        </v-autocomplete>
        <v-select
            v-model="userSpecSelectedId"
            label="Специализация"
            :items="userSpecs"
            item-value="id"
            item-text="name"
            :rules="formFieldRules.required"
            class="mx-4 mb-4" outlined dense hide-details="auto"/>
        <v-textarea v-model="commentAdmin" label="Комментарий о специалисте" class="mx-4 mb-4" rows="1" auto-grow hide-details outlined dense/>
        <v-divider class="mb-4"/>
        <v-autocomplete
            v-model="comRoomSelectedId"
            :items="comRoomsWithComPlaces"
            :search-input.sync="comRoomText"
            label="Кабинет"
            outlined
            dense
            hide-details="auto"
            item-text="name"
            item-value="id"
            :rules="formFieldRules.required"
            class="mx-4 mb-4"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Не найдено</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:item="{item}">
            <v-list-item-content style="width:360px">
              <v-list-item-title>{{item.sid}} {{item.name}}</v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                <span class="mr-1">Местоположение:</span>
                {{item.comFloor.name}}
                <v-icon small>mdi-chevron-left</v-icon>
                {{item.comFloor.comBuilding.comBranch.name}}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-wrap">
                <span class="mr-1">Рабочие места:</span>
                <span v-for="(comPlace, index) in item.comPlaces" :key="item.id+'_'+comPlace.id" class="mr-1">
                  {{comPlace.name}}{{index===item.comPlaces.length-1 ? '' : ','}}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
          <template v-slot:selection="{item}">
            <div class="mr-2">
              {{item.sid}} {{item.name}} &#60; {{item.comFloor.comBuilding.comBranch.name}}
            </div>
          </template>
        </v-autocomplete>
        <v-select
            v-model="comPlaceSelectedId"
            label="Рабочее место"
            :items="comPlaces"
            item-value="id"
            item-text="name"
            class="mx-4 mb-4" outlined dense hide-details="auto"/>
        <v-divider class="mb-4"/>
        <v-menu v-model="beginDateState.isModalOpen" :close-on-content-click="false" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-bind="attrs" v-on="on"
                v-model="beginDateState.modalData"
                label="Дата" class="mx-4 mb-4" outlined dense hide-details="auto" readonly/>
          </template>
          <v-date-picker
              :first-day-of-week="1"
              v-model="beginDateState.modalData1"
              @change="beginDateState.modalClose($tools.dateTimeFormat(beginDateState.modalData1, 'DD.MM.YYYY'), beginDateState.modalData1)"
              no-title
              :width="407"
          />
        </v-menu>

        <time-range class="mx-0 px-1 mb-4" :begin.sync="beginTime" :end.sync="endTime" :dayTimeBegin="dayTimeBegin" :dayTimeEnd="dayTimeEnd"/>

        <v-row class="mx-0 mb-2" no-gutters>
          <v-col :cols="dataState.modalData.type==='workVisit' || dataState.modalData.type==='workVisitReserve' ? 6 : 12" class="px-4">
            <v-select v-model="dataState.modalData.type" label="Тип плана" :items="types" outlined dense hide-details="auto" item-text="name"/>
          </v-col>
          <v-col v-if="dataState.modalData.type==='workVisit' || dataState.modalData.type==='workVisitReserve'" cols="6" class="pr-4">
            <v-select v-model="dataState.modalData.gridMinutes" label="Интервал визитов" :items="gridMinutesList" outlined dense hide-details="auto" item-text="name"/>
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-row no-gutters>
          <v-col cols="6"><v-btn block text @click="dataState.modalClose();$emit('cancel')">Отмена</v-btn></v-col>
          <v-col cols="6">
            <v-btn block
                v-if="userSelectedId!==null"
                :href="`/user/spec/${userSelectedId}/`"
                target="_blank" text
            >Профиль</v-btn>
          </v-col>
          <v-col cols="6"><v-btn block color="error" text @click="deleteState.modalOpen()">Удалить</v-btn></v-col>
          <v-col cols="6"><v-btn block text @click="divideState.modalOpen({time:null})">Разделить</v-btn></v-col>
          <v-col cols="12"><v-btn block color="primary" text @click="createOrUpdate()" :loading="updateState.isLoading">Сохранить</v-btn></v-col>
        </v-row>
        <dialog-view
          :open="divideState.isModalOpen"
          :title="'Разделить план работы #'+dataState.modalData.id+'?'"
          @cancel="divideState.modalClose()"
        >
          <template v-slot:content>
            <v-form v-if="divideState.modalData!==null" ref="divideForm">
              <v-text-field
                  v-model="divideState.modalData.time"
                  label="Время"
                  v-mask="'##:##'"
                  :rules="formFieldRules.required"
                  outlined dense hide-details="auto" class="mb-4" clearable
              />
            </v-form>
          </template>
          <template v-slot:actions>
            <v-btn color="primary" text :loading="divideState.isLoading" @click="divide()">Разделить</v-btn>
          </template>
        </dialog-view>
        <dialog-view
          :open="deleteState.isModalOpen"
          :title="'Удалить план работы #'+dataState.modalData.id+'?'"
          @cancel="deleteState.modalClose()"
        >
          <template v-slot:actions>
            <v-btn color="primary" text :loading="deleteState.isLoading" @click="del()">Удалить</v-btn>
          </template>
        </dialog-view>
      </template>
    </card-view>
  </v-form>
</template>

<script>
import State from "@/plugins/state";
import Log from "@/views/Log/Log";
import DialogView from "@/components/DialogView";
import moment from "moment";
import {FormFieldRules} from "@/plugins/formFieldRules";
import axios from "axios";
import Api from "@/Api";
import CardView from "@/components/CardView";
import TimeRange from "@/components/TimeRange";
export default {
  components: {CardView, DialogView, Log,TimeRange},
  props: ['state', 'users', 'comRooms', 'events'],
  data: () => ({
    dataState: new State(),
    updateState: new State(),
    divideState: new State(),
    deleteState: new State(),

    userSelectedId:null,
    usersText:null,
    commentAdmin:null,

    userSpecs:[],
    userSpecSelectedId:null,
    comRoomsWithComPlaces:[],
    comRoomSelectedId:null,
    comRoomText:null,

    comPlaces:[],
    comPlaceSelectedId:null,


    formFieldRules: FormFieldRules,
    types:[
      // {name:'Прием',value:'workVisit'},
      // {name:'Прием (Резервное время)',value:'workVisitReserve'},
      // {name:'Рабочее время',value:'work'},
      // {name:'Обед',value:'eat'},
      // {name:'Перерыв',value:'break'},
      {name:'Прием (Самозапись вкл)',value:'workVisit'},
      {name:'Прием (Самозапись откл)',value:'workVisitReserve'},
      {name:'Прием отключен',value:'work'},
      {name:'Перерыв, обед',value:'eat'},
      {name:'Отпуск, больничный',value:'break'},
    ],
    gridMinutesList:[
      {name:'5 минут',value:5},
      {name:'10 минут',value:10},
      {name:'15 минут',value:15},
      {name:'20 минут',value:20},
      {name:'30 минут',value:30},
      {name:'45 минут',value:45},
      {name:'60 минут',value:60}
    ],

    beginDateState:new State(),
    beginTime:null,
    endTime:null,

    dayTimeBegin:null,
    dayTimeEnd:null
  }),
  mounted(){
    this.comRoomsWithComPlaces = this.comRooms.filter(room => room.comPlaces.length>0)
  },
  watch: {
    state: {
      deep: true,
      handler() {
        if(this.state.isModalOpen){
         
          this.dataState.modalOpen(JSON.parse(JSON.stringify(this.state.modalData)));
          this.$refs.form.resetValidation();

          this.userSelectedId = null;
          this.userSpecs = [];
          this.userSpecSelectedId = null;
          this.commentAdmin = null;

          this.beginDateState.modalData = this.$tools.dateTimeFormat(this.dataState.modalData.begin, 'DD.MM.YYYY');
          this.beginDateState.modalData1 = this.$tools.dateTimeFormat(this.dataState.modalData.begin, 'YYYY-MM-DD');

          this.beginTime = moment(this.dataState.modalData.begin, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
          this.endTime = moment(this.dataState.modalData.end, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');

          this.users.forEach(v=>{
            v.name = v.lastName+' '+v.firstName+' '+v.secondName;
          });

          this.comRoomSelectedId = this.dataState.modalData.comPlace.comRoom.id;
          setTimeout(()=>{
            this.comPlaceSelectedId = this.dataState.modalData.comPlace.id;
          }, 50);
          console.log(this.state.modalData)
          if(typeof this.state.modalData.id!=='undefined'){
            this.userSelectedId = this.dataState.modalData.userSpec.user.id;
            this.userSpecs = this.users.filter(v=>v.id===this.userSelectedId)[0].userSpecs;
            this.userSpecs.forEach((v)=>{
              v.name = v.spec.name+' < '+v.comDiv.comBranch.name;
            });
            if(this.dataState.modalData.userSpec.id!==null) {
              let userSpecs = this.userSpecs.filter(v => v.id === this.dataState.modalData.userSpec.id);
              if(userSpecs.length>0){
                this.userSpecSelectedId = userSpecs[0].id;
                this.commentAdmin = userSpecs[0].commentAdmin;
              }
            }
          }

          //dayTimeEnd
          if(this.dataState.modalData.dayTimeBegin&&this.dataState.modalData.dayTimeEnd){
            this.dayTimeBegin = this.dataState.modalData.dayTimeBegin
            this.dayTimeEnd = this.dataState.modalData.dayTimeEnd
          }
        }
      },
    },
    userSelectedId() {
        if(this.userSelectedId) {
          this.userSpecs = this.users.filter(v=>v.id===this.userSelectedId)[0].userSpecs;
          this.userSpecs.forEach((v)=>{
            v.name = v.spec.name+' < '+v.comDiv.comBranch.name;
          });
          if(this.userSpecs.length>0&&typeof this.dataState.modalData.userSpec.id === 'undefined'||this.dataState.modalData.userSpec.user.id!==this.userSelectedId){         
            this.userSpecSelectedId = this.userSpecs[0].id;
            this.commentAdmin = this.userSpecs[0].commentAdmin;
          }
        }
    },
    userSpecSelectedId(){
      if(this.userSpecs.length>0 && this.userSpecSelectedId!==null)
        this.commentAdmin = this.userSpecs.filter(v=>v.id===this.userSpecSelectedId)[0].commentAdmin;
    },
    comRoomSelectedId(){
      this.comPlaces = this.comRooms.filter(v=>v.id===this.comRoomSelectedId)[0].comPlaces;
      this.comPlaceSelectedId = this.comPlaces[0].id;
    }
  },
  methods: {
    divide() {
      if (this.$refs.divideForm.validate()) {
        this.divideState.stateLoading();
        axios.put(Api.host+'/userSpecPlan/'+this.dataState.modalData.id+'/divide/', this.divideState.modalData)
            .then(()=> {
              this.divideState.stateSuccess();
              this.divideState.modalClose();
              this.$store.commit('alertSuccess', 'План работы разделен');
              this.$emit('createdUpdatedDeleted');
              this.dataState.modalClose();
            })
            .catch((e)=> {
              this.$store.commit('alertError', e.response.data.message);
              this.divideState.stateError();
              this.divideState.modalClose();
              console.log(e)
            });
      }
    },
    del() {
      this.deleteState.stateLoading();
      axios.delete(Api.host + '/userSpecPlan/' + this.dataState.modalData.id + '/')
          .then(() => {
            this.deleteState.stateSuccess();
            this.deleteState.modalClose();
            this.$store.commit('alertSuccess', 'План работы удален');
            this.$emit('createdUpdatedDeleted');
            this.dataState.modalClose();
          })
          .catch((e) => {
            this.$store.commit('alertError', e.response.data.message);
            this.deleteState.stateError();
            this.deleteState.modalClose();
            console.log(e)
          });
    },
    createOrUpdate(){
      //validation between event
      let timeValidation = true
     
      if (this.events) {
        this.events.filter(event => event.type != 'userSpecPlanNew').forEach(event =>{
          let category = event.category
          let placeCat = this.comPlaceSelectedId
          let start = moment(this.$tools.dateTimeFormat(event.start, 'YYYY-MM-DD HH:mm'))
          let end = moment(this.$tools.dateTimeFormat(event.end, 'YYYY-MM-DD HH:mm'))

          let newEventStart = moment(this.beginDateState.modalData+' '+this.beginTime, 'DD.MM.YYYY HH:mm')
          let newEventEnd = moment(this.beginDateState.modalData+' '+this.endTime, 'DD.MM.YYYY HH:mm')

          // let user = event.userSpecPlan.userSpec.id
          // let newEventUser = this.userSpecSelectedId

          if (
            category==placeCat&&
            //user!=newEventUser&&
            event.userSpecPlan.id != this.dataState.modalData.id&&(
            newEventStart.isBetween(start,end)||
            newEventEnd.isBetween(start,end)||
            start.isBetween(newEventStart,newEventEnd)||
            end.isBetween(newEventStart,newEventEnd)||
            newEventStart.isSame(start)&&newEventEnd.isSame(end))
          ) {
            timeValidation = false
            this.$store.commit('alertError', 'Ошибка перемещения, время недоступно');
          } 
        })
      }
 
      if (this.$refs.form.validate()&&timeValidation) {
        this.updateState.stateLoading();
        if(typeof this.dataState.modalData.id==='undefined'){
          axios.post(Api.host+'/userSpecPlan/', {
            comPlace:this.comPlaceSelectedId,
            userSpec:this.userSpecSelectedId,
            begin:moment(this.beginDateState.modalData+' '+this.beginTime+':00', 'DD.MM.YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
            end:moment(this.beginDateState.modalData+' '+this.endTime+':00', 'DD.MM.YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
            type:this.dataState.modalData.type,
            gridMinutes:this.dataState.modalData.gridMinutes,
            userSpecCommentAdmin:this.commentAdmin
          })
              .then(()=> {
                this.updateState.stateSuccess();
                this.$store.commit('alertSuccess', 'План работы создан');
                this.$emit('createdUpdatedDeleted');
                this.dataState.modalClose();
              })
              .catch((e)=> {
                this.updateState.stateError();
                this.$store.commit('alertError', e.response.data.message);
              });
        } else {
          axios.put(Api.host+'/userSpecPlan/'+this.dataState.modalData.id+'/', {
            comPlace:this.comPlaceSelectedId,
            userSpec:this.userSpecSelectedId,
            begin:moment(this.beginDateState.modalData+' '+this.beginTime+':00', 'DD.MM.YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
            end:moment(this.beginDateState.modalData+' '+this.endTime+':00', 'DD.MM.YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
            type:this.dataState.modalData.type,
            gridMinutes:this.dataState.modalData.gridMinutes,
            userSpecCommentAdmin:this.commentAdmin
          })
              .then(()=> {
                this.updateState.stateSuccess();
                this.$store.commit('alertSuccess', 'План работы обновлен');
                this.$emit('createdUpdatedDeleted');
                this.dataState.modalClose();
              })
              .catch((e)=> {
                this.$store.commit('alertError', e.response.data.message);
                this.updateState.stateError();
                console.log(e)
              });
        }
      }
    },
  }
}
</script>


